@tailwind base;
@tailwind components;
@tailwind utilities;

@import './app/utilities/toaster/toaster.styles.less';

@primary-color: #4C1D95;

html {
   font-family: Rubik, Roboto, "Segoe UI", "Helvetica Neue", "Arial", sans-serif;
   font-size: 14px;
}

body{
  background-image: url(/assets/login_bg.jpg);
  background-attachment:fixed ;
   background-size: cover;

}

h1 { @apply text-4xl font-medium; }

h2 { @apply text-2xl font-medium; }

h3 { @apply text-xl font-medium; }

h4 { @apply font-medium; }

.card { @apply rounded-2xl p-10 border border-gray-50 shadow-lg bg-white; }

div.input {
   @apply flex flex-row items-baseline bg-indigo-50 rounded-md;
   @apply border border-gray-200 hover:border-gray-400 transition;

   &.invalid { @apply bg-red-50; }

   & > input { @apply flex-1; }
}

input, textarea {
   @apply bg-transparent py-1 px-2;
   @apply outline-none focus:outline-none;
}

a { @apply underline cursor-pointer text-indigo-400 hover:text-indigo-500; }

.btn {
   @apply py-1 px-3 border border-gray-200 rounded;
   @apply hover:bg-gray-100 active:bg-gray-200;

   &.small {
      @apply text-sm px-1 py-px;
   }

   &:disabled {
      @apply opacity-75 pointer-events-none;
   }

   &.blue {
      @apply text-white border-blue-500;
      @apply bg-blue-500 hover:bg-blue-600 active:bg-blue-700;
   }
   &.yellow {
      @apply text-white border-yellow-500;
      @apply bg-yellow-500 hover:bg-yellow-600 active:bg-yellow-700;
   }
   &.indigo {
      @apply text-white border-indigo-500;
      @apply bg-indigo-500 hover:bg-indigo-600 active:bg-indigo-700;
   }
}

.field-error { @apply text-sm text-red-500; }

table:not(.no-style) {
   @apply w-full;

   th, td { @apply border-t border-b px-2; }
   th { @apply text-left; }
   td { @apply py-1; }
}

.info-list {
   @apply block;
   @apply md:table;

   & > .row {
      @apply md:table-row-group;

      & > .label {
         @apply font-medium;
         @apply md:table-cell md:align-baseline md:pr-2;
      }
      & > .value {
         @apply md:table-cell;
      }
   }
}

.tabs {
   @apply flex flex-row w-full border-b border-gray-300 pb-1;

   & > .tab {
      @apply text-lg font-medium rounded-lg px-3 py-2 mr-1 ml-2;
      @apply cursor-pointer hover:bg-blue-200;
      @apply border border-blue-100;

      &.active {
         @apply bg-blue-400 text-white;
      }
   }
}

.note {
   @apply py-1 px-3 mt-4;
   padding-bottom: 1.5rem;
   border-color: rgba(250, 237, 134, .8);
   background: rgba(250, 237, 134, .3);;
   border-radius:9px
}

.inactive{
  @apply text-white bg-red-500 hover:text-white hover:bg-red-600;
}
