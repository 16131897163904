.toast-dock {
   display: none; // flex;
   position: fixed;
   z-index: 50;
   pointer-events: none;

   &.from-bottom {
      flex-direction: column-reverse;
      bottom: 0;
      padding-bottom: 50px;

      toast {
         @keyframes fadein {
            from { transform: translateY(50px); opacity: 0; }
            to { transform: translateY(0); opacity: 1; }
         }
         
         @keyframes fadeout {
            from { transform: translateY(0); opacity: 1; }
            to { transform: translateY(50px); opacity: 0; }
         }
      }
   }

   &.from-top {
      flex-direction: column;
      top: 0;

      toast {
         @keyframes fadein {
            from { transform: translateY(-50px); opacity: 0; }
            to { transform: translateY(0); opacity: 1; }
         }
         
         @keyframes fadeout {
            from { transform: translateY(0); opacity: 1; }
            to { transform: translateY(-50px); opacity: 0; }
         }
      }
   }

   &.from-center { left: 0; right: 0; }
   &.from-left { left: 0; }
   &.from-right { right: 0; }

   &.showing { display: flex !important; }

   toast {
      display: block;
      color: #FFFFFF;
      font-weight: bold;
      text-align: center;
      white-space: pre-wrap;
      overflow-wrap: break-word;

      min-width: 250px;
      max-width: 500px;
      margin: 12px;
      padding: 16px;

      // border-radius: 10px;
      box-shadow: 0 0 37px rgba(8, 21, 66, .35);

      pointer-events: auto;

      animation: fadein 0.1s;
      &.dismiss { animation: fadeout 0.1s; opacity: 0; }
      

      &.error   { background-color: #DC2626; }
      &.success { background-color: #10B981; }
      &.warning { background-color: #F59E0B; color: #000; }
   }
}